<template>
<navbar @onClickLeft="onClickLeft" />
  <div id="l404">
    <div class="name">页面不存在</div>
    <img src="@/assets/images/404.png" alt="">
  </div>
</template>

<script>
// import NavBar from "@/components/navBar.vue";
import {useRouter} from 'vue-router'
export default {
 components:{
  //  navbar: NavBar,
 },
 setup(){
  const router=useRouter()
  const onClickLeft=()=>{
         router.push({path:'/workbench'}) 
  }
  return{onClickLeft}
 }
}
</script>

<style lang='scss'>
#l404{
    @include box2;
    position: relative;
    .name{
        position: absolute;
        top: 50px;
        left: 50%;
    transform: translate(-50%,-50% );
    color: #787471;
    font-size: 30px;
    font-weight: bold;
    }
    img{
        width: 100%;
        height: 100%;
    }
}
</style>